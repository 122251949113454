import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, SecurityContext, inject, type OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ParagraphComponentBase } from '@big-direkt/paragraphs-holder';
import { UiHeadingStyleDirective } from '@big-direkt/ui/directives';
import { type HeadingModel } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-paragraph-heading',
    templateUrl: './heading.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, UiHeadingStyleDirective],
})
export class HeadingComponent extends ParagraphComponentBase implements OnInit {
    private readonly sanitizer = inject(DomSanitizer);

    @HostBinding('class') public classList = 'container mb-0!';

    @Input() public override data!: HeadingModel;
    public html!: string;

    public ngOnInit(): void {
        const level: string = this.sanitizer.sanitize(SecurityContext.HTML, this.data.level) ?? 'div';
        const levelClass: string = this.data.levelClass ? ` ${this.data.levelClass}` : '';
        const content: string = this.sanitizer.sanitize(SecurityContext.HTML, this.data.content) ?? '';

        this.html = `<${level} class="overflow-hidden${levelClass}">${content}</${level}>`;
    }
}
